import React, { useState } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { GoogleAuthProvider, EmailAuthProvider, OAuthProvider, signInWithPopup } from 'firebase/auth';
import { useGlobalState } from '../Context';
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

const StyledFirebaseAuth = () => {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('')
	const [confirmPassword, setConfirmPassword] = useState('')
	const [isSignUp, setIsSignUp] = useState(false)

	const [state, dispatch] = useGlobalState()
	const { auth } = state

	const navigate = useNavigate()

	const handleSignIn = async () => {
		try {
			await signInWithEmailAndPassword(auth, email, password).then((userCredential) => {
				const user = userCredential.user

				// dispatch user to state
			})
		} catch (error) {
			console.error('Error signing in with email and password:', error.message);
		}
	};

	const handleSignUp = async () => {
		if (password !== confirmPassword) {
			console.error("Passwords don't match");
			return;
		}

		try {
			await createUserWithEmailAndPassword(auth, email, password).then((userCredential) => {
				const user = userCredential.user;
				// dispatch user to state
			})
		} catch (error) {
			console.error('Error signing up with email and password:', error.message);
		}
	};

	const handleGoogleSignIn = async () => {
		console.log("Google Sign In")
		const provider = new GoogleAuthProvider();
		try {
			await signInWithPopup(auth, provider)
				.then((result) => {
					// const credential = GoogleAuthProvider.credentialFromResult(result);
					navigate("/dashboard")
				})
				.catch((error) => {
					console.log(error)
				});
		} catch (error) {
			console.error('Error signing in with Google:', error.message);
		}
	};

	const handleMicrosoftSignIn = async () => {
		const provider = new OAuthProvider('microsoft.com');
		try {
			await signInWithPopup(auth, provider)
				.then((result) => {
					// User is signed in.
					// IdP data available in result.additionalUserInfo.profile.

					// Get the OAuth access token and ID Token
					const credential = OAuthProvider.credentialFromResult(result);
					const accessToken = credential.accessToken;
					const idToken = credential.idToken;
				})
				.catch((error) => {
					// Handle error.
				});
		} catch (error) {
			console.error('Error signing in with Microsoft:', error.message);
		}
	};

	const handleEmailChange = (e) => setEmail(e.target.value);
	const handlePasswordChange = (e) => setPassword(e.target.value);
	const handleConfirmPasswordChange = (e) => setConfirmPassword(e.target.value);
	const toggleSignUpMode = () => setIsSignUp(!isSignUp);

	return (
		<div className="max-w- mx-auto w-full sm:w-[400px] mt-10 p-6 bg-white rounded-lg shadow-xl z-50">
			<h2 className="text-2xl font-semibold mb-6 text-center text-gray-700 flex flex-row items-center justify-center">
				<span className="text-white bg-green-500 flex rounded-full hover:bg-gray-100 w-[50px] h-[50px] justify-center items-center pb-1 m-0">re</span>
				<span className="ml-[10px] pb-1 text-4xl text-gray-700">relayerr<span className="text-green-500">.</span></span>
			</h2>
			<div className="mb-4">
				<label htmlFor="email" className="block text-gray-700 font-semibold mb-2">Email:</label>
				<input
					type="email"
					id="email"
					value={email}
					onChange={handleEmailChange}
					className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
				/>
			</div>
			<div className="mb-4">
				<label htmlFor="password" className="block text-gray-700 font-semibold mb-2">Password:</label>
				<input
					type="password"
					id="password"
					value={password}
					onChange={handlePasswordChange}
					className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
				/>
			</div>
			{isSignUp && (
				<div className="mb-6">
					<label htmlFor="confirmPassword" className="block text-gray-700 font-semibold mb-2">Confirm Password:</label>
					<input
						type="password"
						id="confirmPassword"
						value={confirmPassword}
						onChange={handleConfirmPasswordChange}
						className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
					/>
				</div>
			)}
			<button
				onClick={isSignUp ? handleSignUp : handleSignIn}
				className="w-full bg-indigo-500 text-white font-semibold py-2 rounded-md hover:bg-indigo-600 focus:outline-none focus:bg-indigo-600"
			>
				{isSignUp ? 'Sign Up' : 'Sign In'}
			</button>

			<hr className="my-6 mb-3 border-gray-300" />

			<button
				onClick={handleGoogleSignIn}
				className="mt-3 w-full bg-red-500 text-white font-semibold py-2 rounded-md hover:bg-red-600 focus:outline-none focus:bg-red-600"
			>
				Sign {isSignUp ? 'Up' : 'In'} with Google
			</button>
			{/* <button
				onClick={handleMicrosoftSignIn}
				className="mt-3 w-full bg-blue-500 text-white font-semibold py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
			>
				Sign  {isSignUp ? 'Up' : 'In'} with Microsoft
			</button> */}
			<p className="mt-4 text-center text-sm text-gray-500">
				{isSignUp ? 'Already have an account?' : "Don't have an account?"}
				<button className="ml-2 text-blue-500" onClick={toggleSignUpMode}>
					{isSignUp ? 'Sign In' : 'Sign Up'}
				</button>
			</p>
		</div>
	);
};

export default StyledFirebaseAuth;
