// export const firebaseConfig = {
//   apiKey: process.env.REACT_APP_FirebaseApiKey,
//   authDomain: process.env.REACT_APP_FirebaseAuthDomain,
//   projectId: process.env.REACT_APP_FirebaseProjectId,
//   storageBucket: process.env.REACT_APP_FirebaseStorageBucket,
//   messagingSenderId: process.env.REACT_APP_FirebaseMessagingSenderId,
//   appId: process.env.REACT_APP_FirebaseAppId,
// };

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};