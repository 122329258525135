import React from 'react';
import { useGlobalState } from '../Context';

const Overview = () => {
	// Generate random values for team size, unread, and completed drafts
	// const teamSize = Math.floor(Math.random() * 6); // Random number between 0 and 5
	// const unread = Math.floor(Math.random() * 101); // Random number between 0 and 100
	// const completedDrafts = Math.floor(Math.random() * 51); // Random number between 0 and 50

	const [state, dispatch] = useGlobalState()
	const auth = state.auth
	const { emailConnected, teamSize, unread, completedDrafts } = state.account
	const handleConnectEmail = async () => {
		try {
			const token = await auth.currentUser.getIdToken();
			const res = await fetch("https://layerr-api.onrender.com/auth/google", {
				method: "GET",
				headers: {
					"Authorization": token,
					"Content-Type": "application/json"
				}
			})

			if(res.status === 302){
				console.log(res)
				window.location.href = res.headers.location
			}
		} catch (error) {
			console.error("Error authorizing:", error);
		}
	}

	const setDashPath = async (path) => {
		console.log('setting')
		await dispatch({ ...state, dashPath: path })
	}

	return (
		<div className="p-4 sm:flex-row flex-col flex-wrap">
			<h2 className="text-xl font-semibold mb-4">Overview</h2>
			<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
				<div className="bg-white min-w-[150px] p-4 rounded-md shadow-md">
					<h3 className="text-lg font-semibold mb-2">Email Connected</h3>
					<p>{emailConnected ? emailConnected : 'No'}</p>
					{!emailConnected && <button className="w-full px-4 mt-4 bg-gray-500 text-white font-semibold py-2 rounded-lg hover:bg-gray-600 focus:outline-none focus:bg-gray-600" onClick={handleConnectEmail}>Connect</button>}
				</div>
				<div className="bg-white min-w-[150px] p-4 rounded-md shadow-md">
					<h3 className="text-lg font-semibold mb-2">Team Size</h3>
					<p>{teamSize}</p>
					{!teamSize && <button onClick={() => setDashPath('Team')} className="w-full px-4 mt-4 bg-gray-500 text-white font-semibold py-2 rounded-lg hover:bg-gray-600 focus:outline-none focus:bg-gray-600">Manage</button>}
				</div>
				<div className="bg-white min-w-[150px] p-4 rounded-md shadow-md">
					<h3 className="text-lg font-semibold mb-2">Unread</h3>
					<p>{unread}</p>
				</div>
				<div className="bg-white min-w-[150px] p-4 rounded-md shadow-md">
					<h3 className="text-lg font-semibold mb-2">Completed Drafts</h3>
					<p>{completedDrafts}</p>
				</div>
			</div>
		</div>
	);
};

export default Overview;
