import React, { useEffect, useContext, createContext, useReducer } from 'react'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import { getAuth } from "firebase/auth"
import { firebaseConfig } from './config/firebase.config'

console.log('Initializing state...')

const app = firebase.initializeApp(firebaseConfig)
const auth = getAuth(app)

const globalState = {
	user: false,
	app,
	auth,
	dashPath: 'Overview',
	account: {
		emailConnected: false,
		teamSize: 0,
		unread: 0,
		completedDrafts: 0
	}
}

const GlobalStateContext = createContext(globalState)
const DispatchStateContext = createContext(undefined)

const useGlobalState = () => [
	useContext(GlobalStateContext),
	useContext(DispatchStateContext)
]

const GlobalStateProvider = ({ children }) => {
	const [state, dispatch] = useReducer((state, newValue) => ({ ...state, ...newValue }), globalState)
	
	useEffect(() => {
		const auth = async () => {
			await dispatch({...state, user: 'isLoading'})
			return await state.app.auth().onAuthStateChanged(async (user) => {
				let userResponse = user
				if(user === null){
					userResponse = false
				}
				// console.log('dispatch 1')
				await dispatch({ ...state, user: userResponse })
			})
		}
		auth()
		// eslint-disable-next-line
	}, [])
	
	useEffect(() => {
		const loadState = async () => {
			const stateString = await localStorage.getItem('state')
			await dispatch({...JSON.parse(stateString)})
			console.log("App loading complete")
		}
		
		loadState()
	}, [])


	// useEffect(() => {
	// 	const startAuth = async () => {
	// 		console.log('auth start hook')
	// 		await onAuthStateChanged(auth, async (user) => {
	// 			if (user) {
	// 				console.log("HERE IS THE USER")
	// 				console.log(JSON.stringify(user, null, 4))

	// 				const idToken = await user.getIdToken()
	// 				await AsyncStorage.setItem('user', JSON.stringify({
	// 					uid: user.uid,
	// 					isAnonymous: user.isAnonymous ? true : false,
	// 					id_token: idToken,
	// 					email: user.isAnonymous ? null : user.email
	// 				}))

	// 				await dispatch({
	// 					...state,
	// 					user: {
	// 						uid: user.uid,
	// 						isAnonymous: user.isAnonymous ? true : false,
	// 						id_token: idToken,
	// 						email: user.isAnonymous ? null : user.email
	// 					}
	// 				})

	// 				if (user.isAnonymous) {
	// 					console.log('Anonymous user is signed in:', user.uid)
	// 				} else {
	// 					console.log('Email/password user is signed in:', user.uid)
	// 				}
	// 			} else {
	// 				// try to get user credential from local storage
	// 				try {
	// 					// Alert.alert('No User', 'Reading from local storage', [{text: 'Close', style: 'cancel'}])
	// 					console.log("trying to get user from local storage")
	// 					const cred = await AsyncStorage.getItem("user")
						
	// 					if(!cred){
	// 						throw new Error ('No local user saved')
	// 					}
						
	// 					// Alert.alert('User?', user.user.uid, [{text: 'Close', style: 'cancel'}])
	// 					console.log('test')
	// 					console.log('local user')
	// 					console.log(JSON.parse(cred))
	// 					const user = JSON.parse(cred)
	// 					if(!user){
	// 						// Alert.alert('No Local User', 'Reading local storage succeeded, but there was no user', [{text: 'Close', style: 'cancel'}])

	// 						console.log('first launch')
	// 						// throw 'First launch'
	// 						await dispatch({ ...state, user: false })

	// 						return
	// 					}

	// 					const idToken = user?._tokenResponse?.idToken
						
	// 					await dispatch({
	// 						...state,
	// 						user: {
	// 							uid: user.uid,
	// 							isAnonymous: user.isAnonymous ? true : false,
	// 							id_token: idToken ? idToken : null,
	// 							email: user.isAnonymous ? null : user.email
	// 						}
	// 					})
	// 				} catch (err) {
	// 					// Alert.alert('Local Err', JSON.stringify(err), [{text: 'Close', style: 'cancel'}])

	// 					console.log('Error signing in user from local credential')
	// 					console.log(err)
	// 					await dispatch({ ...state, user: false })
	// 				}
	// 			}
	// 		})
	// 	}
	// 	startAuth()
	// }, [])

	useEffect(() => {
		// console.log("updated state: ", JSON.stringify(state, null, 2))
		// add in a stub here that saves the state locally every time it changes
		const saveStateLocally = async () => {
			await localStorage.setItem('state', JSON.stringify({...state}))
		}
		console.log(state)
		saveStateLocally()
	}, [state])

	return (
		<GlobalStateContext.Provider value={state}>
			<DispatchStateContext.Provider value={dispatch}>
				{children}
			</DispatchStateContext.Provider>
		</GlobalStateContext.Provider>
	)
}

export {
	GlobalStateProvider,
	useGlobalState,
}