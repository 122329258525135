import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import map_svg from '../components/map.svg'
import OwnerNavbar from "../components/OwnerNavbar";
import StyledFirebaseAuth from "./StyledFirebaseAuth";
import { useGlobalState } from "../Context";
import SideNav from "../components/SideNav";
import Overview from "../components/Overview";

const Dashboard = () => {
	const navigate = useNavigate()
	const [state] = useGlobalState()

	useEffect(() => {
		// check if user exists
		if(state.user){
			navigate("/dashboard")
		}
	}, [])


	return (
		<div className="h-full flex flex-row flex-1">
			{/* <OwnerNavbar /> */}
			<div className="w-[250px] min-w-[250px]">
				<SideNav />
			</div>
			<div className="w-4/5 h-full p-4">
				{state.dashPath === 'Overview' && <Overview />}
			</div>
		</div>
	)
}

export default Dashboard;
