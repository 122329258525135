import React from 'react'
import denim from './jeans.svg'

export default function Footer() {
    return (
        <footer className="bg-white">
            <div className="container mx-auto px-8">
                <div className="w-full flex flex-col md:flex-row py-6">
                    <div className="flex-1 mb-6 text-black items-center flex justify-center">
                        <a className="toggleColour text-white no-underline hover:no-underline font-bold text-2xl lg:text-4xl" href="/" style={{ display: 'flex', alignItems: 'center', padding: '10px' }}>
                            <span className="text-white bg-green-500 flex rounded-full hover:bg-gray-100 w-[50px] h-[50px] justify-center items-center pb-1 m-0">re</span>
                            <span style={{ marginLeft: '10px' }} className="pb-1 text-green-500">relayerr</span>
                        </a>
                    </div>
                    <div className="flex-1">
                        <p className="uppercase text-gray-500 md:mb-6">More Stuff</p>
                        <ul className="list-reset mb-6">
                            <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                                <a href="/get-started" className="no-underline hover:underline text-gray-800 hover:text-pink-500">How It Works</a>
                            </li>
                        </ul>
                    </div>
                    <div className="flex-1">
                        <ul className="list-reset mb-6 md:mt-12">
                            <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                                <a href="mailto:build@with-denim.com" className="no-underline hover:underline text-gray-800 hover:text-pink-500">Support</a>
                            </li>
                        </ul>
                    </div>
                    <div className="flex-1">
                        <ul className="list-reset mb-6 md:mt-12">
                            <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                                <a href="/privacy" target="_blank" rel="noreferrer" className="no-underline hover:underline text-gray-800 hover:text-pink-500">Privacy</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    )
}