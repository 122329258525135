import { useEffect } from "react";
import Logout from "../components/auth/Logout"
import Center from "../components/utils/Center"
import Landing from './Landing'
const Home = (props) => {
  useEffect(() => {}, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Landing />
  );
};

export default Home;
