import React, { useState } from 'react';
import { useGlobalState } from '../Context';

const SideNav = () => {
	const [state, dispatch] = useGlobalState()
	const selectedOption = state.dashPath || 'Overview'

	const handleOptionClick = async (option) => {
		await dispatch({...state, dashPath: option})
	}

	return (
		<div className="flex flex-col w-64 bg-gray-100 h-full">
			<div className="p-4 border-b border-gray-200">
				<h1 className="text-3xl font-bold text-center">relayerr<span className="text-green-500 text-4xl">.</span></h1>
			</div>
			<div className="flex-1 overflow-y-auto">
				<div className="py-2">
					{/* <h2 className="text-lg font-semibold mb-2 text-center">Navigation</h2> */}
					<ul>
						<li
							className={`cursor-pointer px-4 py-2 transition ${selectedOption === 'Overview' ? 'bg-gray-300' : 'hover:bg-gray-200 '}`}
							onClick={() => handleOptionClick('Overview')}
						>
							Overview
						</li>
						<li
							className={`cursor-pointer px-4 py-2 transition ${selectedOption === 'Outbound' ? 'bg-gray-300' : 'hover:bg-gray-200 '}`}
							onClick={() => handleOptionClick('Outbound')}
						>
							Outbound
						</li>
						<li
							className={`cursor-pointer px-4 py-2 transition ${selectedOption === 'Inbound' ? 'bg-gray-300' : 'hover:bg-gray-200 '}`}
							onClick={() => handleOptionClick('Inbound')}
						>
							Inbound
						</li>
						<li
							className={`cursor-pointer px-4 py-2 transition ${selectedOption === 'Team' ? 'bg-gray-300' : 'hover:bg-gray-200 '}`}
							onClick={() => handleOptionClick('Team')}
						>
							Team Stats
						</li>
						<li
							className={`cursor-pointer px-4 py-2 transition ${selectedOption === 'Filter' ? 'bg-gray-300' : 'hover:bg-gray-200 '}`}
							onClick={() => handleOptionClick('Filter')}
						>
							Relay Filter
						</li>
					</ul>
				</div>
			</div>
		</div>
	)
}

export default SideNav