import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Center from "./components/utils/Center";
import { useGlobalState } from "./Context";

import Home from "./screens/Home"
import Login from "./screens/Login"
import Dashboard from "./screens/Dashboard"


function App() {
	const [state] = useGlobalState()
	const { auth, user } = state

	useEffect(() => {
		auth.onAuthStateChanged((user) => {
			if (user) {
				console.info("User detected.");
			} else {
				console.info("No user detected");
			}
		})
	}, [])

	return (
		<BrowserRouter basename="/">
			<Routes>
				<Route key="1" exact path="/" element={<Home />} />
				<Route key="2" path="/sign-in" element={<Login />} />
				{user && user != 'isLoading' && <Route key="3" path="/dashboard" element={<Dashboard />} />}
			</Routes>
		</BrowserRouter>
	);
}

export default App;
